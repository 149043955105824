export default {
  apiKey: 'AIzaSyDI9RkiZj1IlL9oK4gkLL4gGSi3SO5V-IM',
  authDomain: 'first-project-6daea.firebaseapp.com',
  databaseURL: 'https://first-project-6daea.firebaseio.com',
  projectId: 'first-project-6daea',
  storageBucket: 'first-project-6daea.appspot.com',
  messagingSenderId: '508842986473',
  appId: '1:508842986473:web:0eb83370f171824756d978',
  measurementId: 'G-8Z6KVVH1D0'
}
